import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { backendApi } from '../services/api';
import authReducer from './slices/auth';
import { readPersistedState, persistState } from './persistor';

export const store = configureStore({
  reducer: {
    // add the generated reducer as a specific top-level slice
    [backendApi.reducerPath]: backendApi.reducer,
    auth: authReducer,
  },
  // adding the api middleware enables caching, invalidation, polling,
  // and other useful features of 'rtk-query'
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([backendApi.middleware, persistState]),

  preloadedState: readPersistedState(),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
