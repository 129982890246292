import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { setCredentials } from '../../redux/slices/auth';
import { backendApi } from '../../services/api';

const Logout = () => {
  const dispatch = useDispatch();
  dispatch(setCredentials({ user: null, token: null }));
  // reset api state in redux immediately, clearing cached entries
  dispatch(backendApi.util.resetApiState());
  return <Navigate to="/account/login" />;
};

export default Logout;
