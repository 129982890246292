import queryString from 'query-string';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Using RTK Query
// https://redux-toolkit.js.org/rtk-query/usage/queries

let BASE_URL = '/api/';
// 'npm run build' will strip out this condition:
if (process.env.NODE_ENV !== 'production') {
  BASE_URL = process.env.REACT_APP_API_ENDPOINT ?? 'http://localhost:8000/api/';
}

// Creates URL with given url and query parameters.
const genUrl = (url, params, defaultParams = {}) => {
  const qp = queryString.stringify({
    ...defaultParams,
    ...params,
  });
  return `${url}${qp}`;
};

const getSecretHeader = (secret) =>
  secret ? { 'PIPE-Secret': secret } : undefined;

export const backendApi = createApi({
  reducerPath: 'backendApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // always include authentication token in headers if available
      const token = getState().auth.token;
      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      return headers;
    },
  }),

  // cache tag types for automated re-fetching
  tagTypes: [
    'FollowUp',
    'FollowUpAttachment',
    'Organization',
    'Ticket',
    'TicketList',
    'TicketTag',
    'UsedTicketTag',
  ],

  endpoints: (builder) => ({
    // create a new follow-up
    createFollowUp: builder.mutation({
      query: ({ secret, ...data }) => ({
        // url: `followups/create/`,
        url: `followups/`,
        method: 'POST',
        body: data,
        headers: getSecretHeader(secret),
      }),
      invalidatesTags: ['FollowUp', 'FollowUpAttachment'],
    }),

    // delete single followupattachment
    deleteFollowUpAttachment: builder.mutation({
      query: (id) => ({
        url: `followupattachments/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FollowUpAttachment'],
    }),

    // fetch follow-ups
    listFollowUps: builder.query({
      query: ({ secret, ...params }) => ({
        url: genUrl('followups/?', params),
        headers: getSecretHeader(secret),
      }),
      providesTags: ['FollowUp'],
    }),

    // fetch follow-up attachments
    listFollowUpAttachments: builder.query({
      // query: (params) => genUrl('followupattachments/?', params),
      query: ({ secret, ...params }) => ({
        url: genUrl('followupattachments/?', params),
        headers: getSecretHeader(secret),
      }),
      providesTags: ['FollowUpAttachment'],
    }),

    // fetch tickets
    listTickets: builder.query({
      query: (params) => genUrl('tickets/?', params),
      providesTags: ['TicketList'],
    }),

    // login request
    login: builder.mutation({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),

    // fetch one ticket with details
    ticketDetails: builder.query({
      query: ({ ticketId, secret }) => ({
        url: `tickets/${ticketId}/`,
        headers: getSecretHeader(secret),
      }),
      providesTags: ['Ticket'],
    }),

    listTicketTags: builder.query({
      query: ({ ticketId }) => `tickets/${ticketId}/tags/`,
      providesTags: ['TicketTag'],
    }),

    listUsedTicketTags: builder.query({
      query: () => `tickets/usedtags/`,
      providesTags: ['UsedTicketTag'],
    }),

    organizationDetails: builder.query({
      query: ({ organizationId, secret }) => ({
        url: `organizations/${organizationId}/`,
      }),
      providesTags: ['Organization'],
    }),

    updatePartnerAssignment: builder.mutation({
      query: ({ assignmentId, data }) => ({
        url: `partnerassignments/${assignmentId}/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Ticket', 'TicketList'],
    }),

    updateTicket: builder.mutation({
      query: ({ ticketId, data }) => ({
        url: `tickets/${ticketId}/`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Ticket', 'TicketList'],
    }),

    updateTicketPartnerAssignments: builder.mutation({
      query: ({ ticketId, assignments }) => ({
        url: `tickets/${ticketId}/partnerassignments/`,
        method: 'PUT',
        body: assignments,
      }),
      invalidatesTags: ['Ticket', 'TicketList'],
    }),

    updateTicketTags: builder.mutation({
      query: ({ ticketId, tags }) => ({
        url: `tickets/${ticketId}/tags/`,
        method: 'PUT',
        body: tags,
      }),
      invalidatesTags: ['TicketTag', 'TicketList', 'UsedTicketTag'],
    }),

    updateTicketUserAssignments: builder.mutation({
      query: ({ ticketId, assignments }) => ({
        url: `tickets/${ticketId}/userassignments/`,
        method: 'PUT',
        body: assignments,
      }),
      invalidatesTags: ['Ticket', 'TicketList'],
    }),
  }),
});

// Fetch API does not yet support reading upload progress.
// We have to use good old XMLHttpRequest API for file uploads.
export const uploadFile = ({
  authToken,
  type,
  name,
  data,
  onComplete,
  onProgress,
}) => {
  const url = `${BASE_URL}upload/file/`;
  const request = new XMLHttpRequest();

  if (onProgress) {
    request.upload.addEventListener('progress', (event) => {
      const progress = (event.loaded / event.total) * 100;
      onProgress(progress);
    });
  }

  if (onComplete) {
    request.addEventListener('load', (event) => {
      onComplete({
        status: request.status,
        response: JSON.parse(request.response),
      });
    });
  }

  request.open('POST', url);
  request.setRequestHeader('Content-Type', type);
  request.setRequestHeader(
    'Content-Disposition',
    `attachment; filename=${name}`,
  );

  if (authToken) {
    request.setRequestHeader('Authorization', `Token ${authToken}`);
  }

  request.send(data);
  return request;
};

export const {
  useCreateFollowUpMutation,
  useDeleteFollowUpAttachmentMutation,
  useListFollowUpsQuery,
  useListFollowUpAttachmentsQuery,
  useListTicketsQuery,
  useLoginMutation,
  useOrganizationDetailsQuery,
  useTicketDetailsQuery,
  useListTicketTagsQuery,
  useListUsedTicketTagsQuery,
  useUpdatePartnerAssignmentMutation,
  useUpdateTicketMutation,
  useUpdateTicketPartnerAssignmentsMutation,
  useUpdateTicketUserAssignmentsMutation,
  useUpdateTicketTagsMutation,
  useUploadFileMutation,
} = backendApi;
